<template>
  <div class="conversionReport">
    <div class="title">
      <h2>Conversion Report</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form v-model="conversionReport" :inline="true" label-width="100px">
              <el-row>
                <el-col :sm="12" :lg="12" :md="12" :xl="12">
                  <el-form-item label="OfferId(s)">
                    <el-input
                      v-model="conversionReport.offerIds"
                      placeholder="eg: 22062 or 22062,15673"
                      style="width:240px"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :sm="12" :lg="12" :md="12" :xl="12">
                  <el-form-item label="Source(s)">
                    <group-select
                      v-model="conversionReport.sourceIds"
                      :data-source="sourcesList"
                      multiple
                      reserve-keyword
                      :loading="sourceLoading"
                      clearable
                      filterable
                      placeholder="请选择"
                      style="width:240px;"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :sm="12" :lg="12" :md="12" :xl="12">
                  <el-form-item label="Affiliate(s)">
                    <group-select
                      v-model="conversionReport.affiliateIds"
                      :data-source="affiliatesGroupList"
                      multiple
                      reserve-keyword
                      :loading="affiliateLoading"
                      clearable
                      filterable
                      placeholder="请选择"
                      style="width:240px;"
                    />
                  </el-form-item>
                </el-col>
                <el-col :sm="12" :lg="12" :md="12" :xl="12">
                  <el-form-item label="Status">
                    <el-select
                      v-model="conversionReport.status"
                      placeholder="请选择"
                      style="width:240px;"
                    >
                      <el-option
                        v-for="item in statusOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :sm="12" :lg="12" :md="12" :xl="12">
                  <el-form-item label="TimeZone">
                    <el-select v-model="conversionReport.timezone" style="width:380px;">
                      <el-option
                        v-for="item in timezoneOption"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :sm="12" :lg="12" :md="12" :xl="12">
                  <el-form-item label="AffSub">
                    <el-input
                      v-model="conversionReport.affSub"
                      placeholder="write affSub here..."
                      style="width:240px"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-form-item label="Date">
                  <el-date-picker
                    v-model="startToEndDate"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    style="width:240px;"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
              </el-row>

              <el-form-item style="margin-left:100px">
                <el-button type="primary" @click="searchListClick(1)">In Browser</el-button>
                <el-button type="primary" @click="exportExcelFileClick">Download Excel</el-button>
                <el-button type="primary" @click="approveClick">Approve</el-button>
              </el-form-item>
            </el-form>
            <!-- approve提示框 -->
            <el-dialog title="Tip" :visible.sync="approveVisible" width="300px" append-to-body>
              <div class="del-dialog-cnt">{{ this.approveMessage }}</div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="approveVisible = false">cancel</el-button>
                <el-button type="primary" @click="confirmClick">confirm</el-button>
              </span>
            </el-dialog>
          </el-card>
          <el-card shadow="hover">
            <el-table
              v-loading="listLoading"
              style="width:100%;"
              height="70vmin"
              stripe
              border
              :data="conversionReportList"
              @selection-change="handleConversionSelectionChange"
              @select-all="handleConversionAll"
              highlight-current-row
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
                :resizable="false"
              ></el-table-column>
              <el-table-column
                label="No."
                prop="id"
                min-width="60"
                align="center"
                :resizable="false"
              ></el-table-column>
              <el-table-column
                label="Conversion Time"
                prop="conversionTime"
                min-width="170"
                align="center"
                :resizable="false"
              ></el-table-column>
              <el-table-column
                label="Click Time"
                prop="clickTime"
                align="center"
                min-width="170"
                :resizable="false"
              ></el-table-column>
              <el-table-column
                label="Source"
                prop="sourceId"
                min-width="90"
                align="center"
                :resizable="false"
              ></el-table-column>
              <el-table-column
                label="Affiliate"
                prop="affiliateId"
                align="center"
                min-width="90"
                :resizable="false"
              ></el-table-column>
              <el-table-column
                label="OfferId"
                prop="offerId"
                align="center"
                min-width="100"
                :resizable="false"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                label="OfferName"
                prop="offerName"
                align="center"
                min-width="100"
                :resizable="false"
              ></el-table-column>
              <el-table-column
                label="Location"
                prop="trackCountry"
                align="center"
                min-width="90"
                :resizable="false"
              ></el-table-column>
              <el-table-column
                label="Aff_sub ID"
                prop="affiliateSub"
                align="center"
                min-width="150"
                :resizable="false"
              ></el-table-column>
              <el-table-column
                label="Click ID"
                prop="transactionId"
                align="center"
                min-width="200"
                :resizable="false"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                label="Event Name"
                prop="eventName"
                align="center"
                min-width="120"
                :resizable="false"
                show-overflow-tooltip
              ></el-table-column>
              <!-- <el-table-column
                label="Click IP"
                prop="trackIp"
                align="center"
                min-width="120"
                :resizable="false"
              ></el-table-column> -->
              <!-- <el-table-column
                label="Conversion IP"
                prop="ip"
                align="center"
                min-width="120"
                :resizable="false"
              ></el-table-column> -->
              <el-table-column
                label="Status"
                prop="adjusted"
                align="center"
                min-width="100"
                :resizable="false"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.adjusted === 0">approved</span>
                  <span v-if="scope.row.adjusted === 1">pending</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Revenue"
                prop="payoutValue"
                align="center"
                min-width="80"
                :resizable="false"
              ></el-table-column>
              <el-table-column
                label="Payout"
                prop="affPayoutValue"
                align="center"
                min-width="70"
                :resizable="false"
              ></el-table-column>
              <el-table-column
                label="Profit"
                prop="profit"
                align="center"
                min-width="70"
                :resizable="false"
              ></el-table-column>
            </el-table>
            <pagination
              class="block pagePanel"
              :pageTotal="conversionTotal"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
              :pageSize="pageSize"
              :currentPage="currentPage"
            ></pagination>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  import { api, getConversionReport, sendAffPb } from 'api/report';

  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';

  import dateUtils from '@/utils/dateutils.js';
  import qs from 'querystring';
  import { myMixin } from '@/mixins/mixins.js';
  import optionData from '@/assets/js/optionData';
  import { mapState, mapActions } from 'vuex';
  import { filterObject } from 'utils/utils';

  export default {
    mixins: [myMixin],
    components: {
      Pagination,
      GroupSelect,
    },
    data() {
      return {
        affiliateIdArray: [],
        sourceIdArray: [],
        listLoading: false,
        approveVisible: false,
        pageSize: 20,
        currentPage: 1,
        conversionTotal: null,
        idArr: [],
        approveMessage: '',
        approveSelectionList: [],
        conversionReportList: [],
        startToEndDate: '',
        timezoneOption: optionData.timezoneOption,
        statusOptions: [
          {
            label: 'ALL',
            value: null,
          },
          {
            label: 'APPROVED',
            value: '0',
          },
          {
            label: 'PENDING',
            value: '1',
          },
        ],
        conversionReport: {
          sourceIds: null,
          affiliateIds: null,
          status: null,
          offerIds: null,
          fromDate: null,
          toDate: null,
          affSub: null,
          timezone: '+00:00',
        },
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
      ...mapState('source', {
        sourcesList: (state) => state.sourcesList,
        sourceLoading: (state) => state.sourceLoading,
      }),
    },
    mounted() {
      //默认选择
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() + 3600 * 1000 * 24);
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(end);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);
      this.getAffiliateList();
      this.getSourceIdsList();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      ...mapActions('source', ['getSourceIdsList']),
      approveClick() {
        this.approveMessage = '';

        if (!this.approveSelectionList || this.approveSelectionList.length <= 0) {
          this.$message.warning('please select approve data!');
          return;
        }
        if (this.startToEndDate.length !== 2) {
          this.$message.warning('please select date!');
          return;
        }
        this.approveVisible = true;
        this.approveMessage =
          'You choose to change ' +
          this.approveSelectionList.length +
          '  conversions to "Approve"?';
      },
      searchListClick(curPage) {
        this.listLoading = true;
        if (curPage) {
          this.curPage = curPage;
        }
        let param = {
          ...this.conversionReport,
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        param['fromDate'] = this.startToEndDate[0];
        param['toDate'] = this.startToEndDate[1];
        if (Array.isArray(param.sourceIds) && param.sourceIds.length > 0) {
          param.sourceIds = param.sourceIds.join(',');
        } else {
          delete param.sourceIds;
        }
        if (Array.isArray(param.affiliateIds) && param.affiliateIds.length > 0) {
          param.affiliateIds = param.affiliateIds.join(',');
        } else {
          //根据PM有的渠道权限去查询
          let idArr = new Array();
          if (this.affiliateList != null && this.affiliateList.length > 0) {
            this.affiliateList.forEach((item) => idArr.push(item.id));
          }
          param.affiliateIds = idArr.length > 0 ? idArr : null;
        }
        param = filterObject(param);
        getConversionReport(param).then((response) => {
          this.listLoading = false;
          if (response.code === 200) {
            this.conversionReportList = response.result;
            this.conversionTotal = response.total;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      updateClick(row) {
        let param = {
          offerId: row.offerId,
          status: row.status,
        };
        console.log(param);
        //等后端给更新status接口
      },
      handleSizeChange(size) {
        this.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.currentPage = currentPage;
        this.searchListClick();
      },
      exportExcelFileClick() {
        let param = {
          offerIds: null,
          sourceIds: null,
          affiliateIds: null,
          status: null,
        };
        param['fromDate'] = this.startToEndDate[0];
        param['toDate'] = this.startToEndDate[1];
        param['timezone'] = this.conversionReport.timezone;
        param['status'] = this.conversionReport.status;
        if (this.conversionReport.offerIds != null && this.conversionReport.offerIds.length > 0) {
          if (this.conversionReport.offerIds.indexOf('，') != -1) {
            param.offerIds = this.conversionReport.offerIds.replace(/，/gi, ',');
          } else {
            param.offerIds = this.conversionReport.offerIds;
          }
        }

        if (this.conversionReport.sourceIds != null && this.conversionReport.sourceIds.length > 0) {
          param.sourceIds = this.conversionReport.sourceIds.join(',');
        }

        if (
          this.conversionReport.affiliateIds != null &&
          this.conversionReport.affiliateIds.length > 0
        ) {
          param.affiliateIds = this.conversionReport.affiliateIds.join(',');
        }
        param = filterObject(param);
        const strParam = qs.stringify(param);
        window.open(`${api.DOWNLOAD_CONVERSION_REPORT}?${strParam}`, '_blank');
      },

      handleConversionSelectionChange(val) {
        this.approveSelectionList = [];
        this.approveSelectionList = val;
      },
      handleConversionAll(val) {
        this.approveSelectionList = [];
        this.approveSelectionList = val;
      },
      confirmClick() {
        const length = this.approveSelectionList.length;
        this.idArr = [];
        for (let i = 0; i < length; i++) {
          this.idArr.push(this.approveSelectionList[i].id);
        }
        const ids = this.idArr;

        sendAffPb({
          ids,
          fromDate: this.startToEndDate[0],
          toDate: this.startToEndDate[1],
        }).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Approve Success',
              type: 'success',
            });
            this.searchListClick();
          } else {
            this.$message.error('Approve Error:' + response.message);
          }
        });
        this.approveVisible = false;
      },
    },
  };
</script>
<style lang="scss" scoped></style>
<style lang="scss"></style>
